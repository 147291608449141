import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatListState } from '../reducers/chat-list.reducer';
import { CHAT_LIST_FEATURE_KEY } from '../keys';

export const selectState = createFeatureSelector<ChatListState>(CHAT_LIST_FEATURE_KEY);
export const selectItems = createSelector(selectState, (state: ChatListState) => state.items);
export const selectLoading = createSelector(selectState, (state: ChatListState) => state.loading);
export const selectLoaded = createSelector(selectState, (state: ChatListState) => state.loaded);
export const selectUnreadMessagesCount = createSelector(selectState, (state: ChatListState) =>
    state.items.reduce((acc, { countUnreadMessages }) => (acc += countUnreadMessages), 0),
);

export const selectItem = (id: string) => createSelector(selectState, (state) => state.items.find((item) => item.id === id) ?? null);
