import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CHAT_LIST_FEATURE_KEY } from './keys';
import { chatListReducer } from './reducers/chat-list.reducer';
import { ChatListEffects } from './effects/chat-list.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(CHAT_LIST_FEATURE_KEY, chatListReducer), EffectsModule.forFeature([ChatListEffects])],
})
export class ChatListStoreModule {}
