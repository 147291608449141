import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatInfo } from '../models';

export const ChatListActions = createActionGroup({
    source: 'Chat List',
    events: {
        'Get List': emptyProps(),
        'Get List Success': props<{ items: ChatInfo[] }>(),
        'Get List Error': emptyProps(),

        'Mark As Read': props<{ id: string }>(),

        Clear: emptyProps(),
    },
});
