import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { ChatListFacade } from '../facades/chat-list.facade';

export const chatListCleanup: CanDeactivateFn<unknown> = () => {
    const chatListFacade = inject(ChatListFacade);

    chatListFacade.clear();
    return true;
};
