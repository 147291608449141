import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@app/shared/models';
import { ChatInfo, ChatInfoDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getSingleList(): Observable<ChatInfo[]> {
        return this.httpClient
            .get<ApiResponse<ChatInfoDto[]>>(`api/v1/catalogue/single`)
            .pipe(map((response) => response.data.filter((single) => single.hasComments).map((item) => new ChatInfo(item))));
    }
}
