import { ChatInfoDto } from './chat-info.dto';

export class ChatInfo {
    id: string;
    image: string;
    publishTs: Date;
    type: string;
    name: string;
    producers: string;
    status: string;
    countUnreadMessages: number;
    hasComments: boolean;

    constructor(dto: ChatInfoDto) {
        this.id = dto.id;
        this.image = dto.image;
        this.publishTs = new Date(dto.publish_ts * 1000);
        this.type = dto.type;
        this.name = dto.name;
        this.producers = dto.producers;
        this.status = dto.status;
        this.countUnreadMessages = dto.countUnreadMessages;
        this.hasComments = dto.hasComments;
    }
}
