import { createReducer, on } from '@ngrx/store';
import { ChatInfo } from '@app/store/chat-list';
import { ChatListActions } from '../actions/chat-list.actions';

export interface ChatListState {
    items: ChatInfo[];
    loading: boolean;
    loaded: boolean;
}

export const initialState: ChatListState = {
    items: [],
    loading: false,
    loaded: false,
};

export const chatListReducer = createReducer(
    initialState,

    on(
        ChatListActions.getList,
        (state): ChatListState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        ChatListActions.getListSuccess,
        (state, action): ChatListState => ({
            ...state,
            items: action.items,
            loading: false,
            loaded: true,
        }),
    ),
    on(ChatListActions.markAsRead, (state, action): ChatListState => {
        const items = [...state.items];
        const index = items.findIndex((item) => item.id === action.id);
        if (index !== -1) {
            const item = {
                ...items[index],
                countUnreadMessages: 0,
            };
            items.splice(index, 1, item);
        }
        return {
            ...state,
            items,
        };
    }),
    on(ChatListActions.clear, (): ChatListState => ({ ...initialState })),
);
