import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { ChatListActions as chatListActions } from '../actions/chat-list.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class ChatListEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getSingleList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(chatListActions.getList),
            mergeMap(() => {
                return this.httpService.getSingleList().pipe(
                    map((items) => chatListActions.getListSuccess({ items })),
                    catchError(() => of(chatListActions.getListError())),
                );
            }),
        );
    });
}
