import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectItem, selectItems, selectLoaded, selectLoading, selectUnreadMessagesCount } from '../selectors/chat-list.selector';
import { ChatListActions } from '../actions/chat-list.actions';

@Injectable({
    providedIn: 'root',
})
export class ChatListFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    loading$ = this.store.select(selectLoading);
    items$ = this.store.select(selectItems);
    unreadMessagesCount$ = this.store.select(selectUnreadMessagesCount);

    item$ = (id: string) => this.store.select(selectItem(id));

    getList(): void {
        this.store.dispatch(ChatListActions.getList());
    }

    markAsRead(id: string): void {
        this.store.dispatch(ChatListActions.markAsRead({ id }));
    }

    clear(): void {
        this.store.dispatch(ChatListActions.clear());
    }
}
